import React from 'react';

import './Company.css';
const content = [
  {
    title: 'Company Location',
    desc: 'Location: Jumeriah Beach ,The Walk,JBR.',
  },
  {
    title: 'Company profile',
    desc: 'At Beauty Tone Salon our mission is to provide a luxurious and elegant space for women to enhance their beauty and confidence. We strive to offer exceptional hair and makeup services to meet the unique needs of every woman.',
  },
  {
    title: 'Services',
    desc: 'Haircuts and Styling ,\
    Coloring ,\
    Extensions ,\
    Manicures and Pedicures.\
    ',
  },
  {
    title: 'Our Promise',
    desc: "we believe that every woman deserves to feel beautiful and confident. That's why we offer a wide range of hair and makeup services designed to enhance and empower. From simple haircuts to full-day bridal packages, our goal is to provide a personalized and relaxing experience for every woman who walks through our doors. Whether you're getting ready for a special occasion or just looking for a touch-up, we are dedicated to making you feel and look your best.",
  },
  {
    title: 'Our Team',
    desc: "Our international salon team is focused on providing expert beauty services. Our goal is to make every visit to our salon a unique and memorable experience. Whether you're looking for a stylish manicure or simply a touch-up, our team of professionals is here to make you feel pampered and rejuvenated.",
  },
];

export function AppSection({ title, desc, padding = '' }) {
  return (
    <div className="">
      <div className=" bg-black text-white px-2 font-semibold">{title}</div>
      <div
        className={`text-black px-2 py-2 ${padding}`}
        style={{ background: '#e3e6ef' }}
      >
        {desc}
      </div>
    </div>
  );
}

export function Company() {
  return (
    <>
      <div className="h-[100vh] w-full relative">
        <img
          src="/assets/hero.jpeg"
          style={{ objectFit: 'contain' }}
          className=" hidden md:block absolute  top-0 left-0 h-[100%] w-[100%]"
          alt=""
        />
        <img
          src="/assets/hero.jpeg"
          className="  md:hidden absolute  top-0 left-0 h-[100%] w-[100%]"
          alt=""
        />
        {/* <div className=' absolute left-1/2  h-[100%] w-[50%]' style={{background:'#73787c'}}> </div> */}
      </div>
      <div className=" grid  grid-cols-1 sm:grid-cols-3 gap-3 px-4 my-6">
        <div className=" flex  flex-col gap-3">
          {/* <AppSection title={content[0].title} desc={content[0].desc} /> */}
          <AppSection title={content[1].title} desc={content[1].desc} />
          <AppSection title={content[2].title} desc={content[2].desc} />
          <AppSection title={content[3].title} desc={content[3].desc} />

          {/* //padding="pb-16" */}
        </div>
        <div className=" flex  flex-col gap-3">
          <div>
            <div className="">
              <div className=" bg-black text-white px-2 font-semibold">
                About CEO
              </div>
              <div
                className={`text-black px-2 py-2`}
                style={{ background: '#e3e6ef' }}
              >
                <div>
                  <div>
                    Over 20 years of experience in managing and leading teams in
                    the beauty industry Demonstrated strong organizational and
                    communication skills in overseeing daily salon operations
                    and ensuring customer satisfaction.
                  </div>
                </div>
                <li>
                  <strong>Owner at Movenpick Hotel, Dubai 2020-2023</strong>
                </li>
                <li>
                  <strong>Supervisor at Atlantis Hotel, Dubai 2008-2020</strong>
                </li>
                <li>
                  <strong>Hairdresser - Crowne plaza dubai 2006 - 2008</strong>
                </li>
                <li>
                  <strong>
                    Hairdresser - Meridien hotel damascus 2000 - 2006
                  </strong>
                </li>
              </div>
            </div>
          </div>
          <div style={{ background: '#e3e6ef' }}>
            <AppSection title={content[4].title} desc={content[4].desc} />
            <strong style={{ paddingLeft: '8px' }}>Members:</strong>
            <div className=" flex place-content-around	items-center	pl-2 ">
              <li className="grow w-1/2	">
                <a
                  href="https://www.instagram.com/beautytonejbr/"
                  target="_blank"
                >
                  Khaled Mhd Ali Kusaibati
                </a>
              </li>
              <li className="grow w-1/2	">
                <a
                  href="https://instagram.com/beauty_master_universal?igshid=MWI4MTIyMDE="
                  target="_blank"
                >
                  Natalya Dobrovolska
                </a>
              </li>
            </div>
            <div className="flex place-content-around	items-center	pl-2">
              <li className="grow w-1/2	">
                <a
                  href="https://instagram.com/eli_hairdubai?igshid=MWI4MTIyMDE="
                  target="_blank"
                >
                  Eli Hairdubai
                </a>
              </li>
              <li className="grow w-1/2	">
                <a
                  href="https://instagram.com/dari.gusha?igshid=MWI4MTIyMDE="
                  target="_blank"
                >
                  Dari Gusha
                </a>
              </li>
            </div>
          </div>
          {/* padding="pb-[5.1rem]" */}
        </div>
        <div>
          <div className="ml-2">
            <div
              className=" pt-4 pb-[4rem] px-8"
              style={{
                background: '#e3e6ef',
                borderRadius: '40px 0px 0px 40px',
              }}
            >
              <h4 className=" font-bold text-3xl mb-6">Contact Us</h4>
              <div>
                <div>
                  <strong>CEO:</strong>
                  Khaled Mhd Ali Kusaibati
                </div>
                <div>
                  <strong>Mob/WA:</strong>
                  <a href="https://wa.me/+971507218991">+971 (50) 721 8991</a>
                </div>
                <div>
                  <strong>Email:</strong>
                  <a href="mailto:beautytonedubai@gmail.com">
                    beautytonedubai@gmail.com
                  </a>
                </div>
                <div>
                  <strong>instagram:</strong>
                  <a
                    href="https://www.instagram.com/beautytonejbr/"
                    target="_blank"
                  >
                    beautytonejbr
                  </a>
                </div>
                <div>
                  <strong>Languages:</strong>
                  Arabic, English ,Russian ,Persian.
                </div>
                <div>
                  <div>
                    <strong>Visit Us:</strong>
                    <a href="https://g.co/kgs/uhjjwg" target="_blank">
                      Jumeriah Beach ,The Walk,JBR.
                    </a>
                  </div>
                  <strong>website:</strong>
                  <a href="https://beauty-tone-jbr.pages.dev/" target="_blank">
                    https://beauty-tone-jbr.pages.dev
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
