import "./App.css";
// import { HeaderContainer } from "./components";
// import { useBreakpoint } from "./hooks/useBreakpoint";
import { Company } from "./pages/Company";
function App() {
  // const breakpoint =useBreakpoint();
  return (
    <>
      <Company />
      {/* <HeaderContainer /> */}
    </>
  );
}

export default App;
